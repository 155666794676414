import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { getHistoryLoading, getTradeInfo } from 'redux/reducers/transactions/selectors';
import { toFixedNumber } from 'services/utils/toFixedNumber';
import { ILayersData, layersFilter } from '../../../services/utils/layersFilter';
import { getSpreadFee } from '../../../redux/reducers/deposits/selectors';
import { getTradeLayers, getTradeData } from '../../../redux/reducers/trade/selectors';
import { toDinamicDecimals } from '../../../services/utils/numbers';
import { calculatePercent } from '../calculatePercent';
import { changeMainPrice } from '../../../redux/reducers/trade/reducer';
import { getPopUp } from '../../../redux/reducers/popUp/selectors';
import { getPriceLevel } from '../getPriceLevel';

let timeout: ReturnType<typeof setTimeout>;
export interface IMainTradePair {
	layers?: Record<string, any>;
}
const MainTradePair: FC<IMainTradePair> = () => {
	const dispatch = useDispatch();
	const layers = useSelector(getTradeLayers);
	const mainTradePair = useSelector(getTradeInfo);
	const trade = useSelector(getTradeData);
	const tradeInfo = useSelector(getTradeInfo);
	const historyLoading = useSelector(getHistoryLoading);
	const mainCurrency = useSelector(getCurrencyData);
	const spreadFee = useSelector(getSpreadFee);
	const currentPopup = useSelector(getPopUp);
	const currency = mainCurrency.filter((el) => el.exchangeable);

	const [mainPrice, setMainPrice] = useState<string | number>(0);
	const [fromIsFiat, setFromIsFiat] = useState(false);
	const [toIsFiat, setToIsFiat] = useState(false);
	const [isReversed, setIsReversed] = useState(false);
	const [isReversedServer, setIsReversedServer] = useState(false);

	useEffect(() => {
		if (currentPopup.popUpText === 'successTrade') return;

		if (currency && layers && mainTradePair?.pair_code) {
			const fromCode = mainTradePair?.pair_code?.split('_')[0];
			const toCode = mainTradePair?.pair_code?.split('_')[1];
			const fromType = currency.find((item) => item.code === fromCode)?.type;
			const toType = currency.find((item) => item.code === toCode)?.type;
			const tradePairReverse = `${toCode}_${fromCode}`;
			if (fromType === 'fiat' || fromType === 'token') {
				setFromIsFiat(true);
			} else {
				setFromIsFiat(false);
			}
			if (toType === 'fiat' || toType === 'token') {
				setToIsFiat(true);
			} else {
				setToIsFiat(false);
			}
			const layersData: ILayersData | Record<string, any> = layersFilter(
				mainTradePair?.pair_code,
				layers,
			);
			const layersDataReverse: ILayersData | Record<string, any> = layersFilter(
				tradePairReverse,
				layers,
			);

			if (layersData && layersData.data) {
				setIsReversed(layersData.isReversed);
				setIsReversedServer(layersData.isReversedServer);
				const buyLayers: any = layersData.data.buy;
				const sellLayers: any = layersData.data.sell;
				const fromAssetCount = trade.from_asset_count;
				const fromAssetCountRaw = trade.from_asset_count_raw;
				const count = 1;
				let perc: string | number = 0;
				let spreadCalc: string | number = 0;
				let price = 0;
				if (spreadFee) {
					perc = Number(spreadFee);
				}

				if (isReversed) {
					price = Number(Object.values(buyLayers)[0]);
					if (fromAssetCountRaw) {
						const selectedLevelFinal = getPriceLevel(
							sellLayers,
							buyLayers,
							Number(fromAssetCountRaw),
							isReversed,
							'buy',
						);
						price = Number(buyLayers[selectedLevelFinal]);
						// price = Number(sellLayers[selectedLevelFinal]);
					}
					spreadCalc = calculatePercent(Number(perc), Number(count));
					// const spreadCalc = (Number(perc) / 100) * (Number(count) * price);
					// const calculatedValue = toDinamicDecimals(
					// 	(Number(count) + Number(spreadCalc)) * price,
					// 	String(fromType),
					// );
					const calculatedValue = toFixedNumber(
						(Number(count) + Number(spreadCalc)) * price,
						'crypto',
					);

					const formatValue = String(calculatedValue).includes('.')
						? String(calculatedValue).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
						: Number(calculatedValue).toLocaleString('en-US');
					setMainPrice(formatValue);
					dispatch(changeMainPrice(calculatedValue));
				} else if (isReversedServer) {
					price = Number(Object.values(sellLayers)[0]);
					if (fromAssetCountRaw) {
						const selectedLevelFinal = getPriceLevel(
							sellLayers,
							buyLayers,
							Number(fromAssetCountRaw),
							isReversed,
							'buy',
						);
						price = Number(sellLayers[selectedLevelFinal]);
					}
					spreadCalc = calculatePercent(Number(perc), Number(count));
					// const calculatedValue = toDinamicDecimals(
					// 	(Number(count) + Number(spreadCalc)) * price,
					// 	String(fromType),
					// );
					const calculatedValue = toFixedNumber(
						(Number(count) + Number(spreadCalc)) * price,
						'crypto',
					);
					const formatValue = String(calculatedValue).includes('.')
						? String(calculatedValue).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
						: Number(calculatedValue).toLocaleString('en-US');
					setMainPrice(formatValue);
					dispatch(changeMainPrice(calculatedValue));
				} else {
					price = Number(Object.values(sellLayers)[0]);
					if (fromAssetCountRaw) {
						const selectedLevelFinal = getPriceLevel(
							sellLayers,
							buyLayers,
							Number(fromAssetCountRaw),
							isReversed,
							'buy',
						);
						price = Number(sellLayers[selectedLevelFinal]);
					}
					spreadCalc = calculatePercent(Number(perc), Number(count));
					// const calculatedValue = toDinamicDecimals(
					// 	(Number(count) - Number(spreadCalc)) * price,
					// 	String(toType),
					// );
					const calculatedValue = toFixedNumber(
						(Number(count) + Number(spreadCalc)) * price,
						'crypto',
					);
					const formatValue = String(calculatedValue).includes('.')
						? String(calculatedValue).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
						: Number(calculatedValue).toLocaleString('en-US');
					setMainPrice(formatValue);
					dispatch(changeMainPrice(calculatedValue));
				}
			} else if (layersData && !layersData.data) {
				setMainPrice(0);
				dispatch(changeMainPrice(0));
			}
		}
	}, [
		currency,
		currentPopup.popUpText,
		dispatch,
		fromIsFiat,
		isReversed,
		isReversedServer,
		layers,
		mainTradePair?.pair_code,
		spreadFee,
		toIsFiat,
		trade.from_asset_count,
		trade.from_asset_count_raw,
	]);

	const [updatePriceAnim, setUpdatePriceAnim] = useState(false);
	useEffect(() => {}, [currency]);
	useEffect(() => {
		setUpdatePriceAnim(true);
		timeout = setTimeout(() => setUpdatePriceAnim(false), 10000);
		return () => {
			clearTimeout(timeout);
		};
	}, [historyLoading]);

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const [oneText, twoText] = splitText(tradeInfo?.pair_code || 'btc_eur');
	return (
		<div className="instant-trade-title">
			{isReversedServer ? (
				<div className="instant-trade-title__text">
					1 {twoText?.toUpperCase()} ={' '}
					<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>{mainPrice}</p>
					{'  '}
					{oneText.toUpperCase()}
				</div>
			) : (
				<>
					{isReversed ? (
						<div className="instant-trade-title__text">
							1 {twoText?.toUpperCase()} ={' '}
							<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
								{mainPrice}
							</p>
							{'  '}
							{oneText.toUpperCase()}
						</div>
					) : (
						<div className="instant-trade-title__text">
							1 {oneText?.toUpperCase()} ={' '}
							<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
								{mainPrice}
							</p>
							{'  '}
							{twoText.toUpperCase()}
						</div>
					)}
				</>
			)}
			{/* <div className="instant-trade-title__status instant-trade-title__status--up">+0.08%</div> */}
		</div>
	);
};

export default MainTradePair;
